<template>
	<div id="main" class="main" role="main">
		<router-view />
	</div>
</template>

<script>
export default {
	name: "DemoMain",
};
</script>

<style scoped>
.main {
	padding: 2em;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #efefef;
}

h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}
</style>
