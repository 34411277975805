import { createRouter, createWebHistory } from "vue-router";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/fontawesome";
import Home from "../views/DemoHome.vue";
import About from "../views/DemoAbout.vue";
import Product from "../views/DemoProduct.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: About,
	},
	{
		path: "/product/:id",
		name: "Product",
		component: Product,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
