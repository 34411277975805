<template>
	<div class="home">
		<h1 class="title">Initech</h1>
		<p class="subtitle">For all your stapling needs</p>
		<p>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vulputate sodales velit,
			vel suscipit lorem tempor lobortis. Curabitur pretium, sem tincidunt commodo posuere, enim
			metus ultrices libero, vitae cursus est quam vel orci. Morbi felis ipsum, ullamcorper
			efficitur congue ac, sagittis non urna. Fusce ultrices, justo et tempus euismod, eros dui
			suscipit augue, ac eleifend orci diam vitae justo. Sed vitae dapibus felis. In porttitor,
			turpis vel ornare eleifend, magna turpis porttitor sapien, ut rutrum massa metus sit amet
			mauris. Mauris maximus vulputate neque, et euismod urna dignissim egestas. Nulla sodales nec
			nisl sed porttitor. Duis ut lectus non massa porttitor posuere. Duis rhoncus lectus ligula,
			vitae luctus neque efficitur ut. Nullam id nibh id nisi varius euismod. Morbi vehicula, purus
			id porta ullamcorper, neque nisi accumsan leo, in viverra nunc urna eget sem. Maecenas sit
			amet placerat orci, pellentesque scelerisque est. Aliquam ac posuere arcu. Nullam lobortis
			arcu non sem iaculis, sed eleifend erat posuere.
		</p>
	</div>
</template>

<script>
export default {
	name: "DemoHome",
};
</script>
