<template>
	<router-link v-slot="{ href, navigate, isActive, isExactActive }" :to="to" custom>
		<li>
			<!-- eslint-disable-next-line sonarjs/no-vue-bypass-sanitization -- href is safe, not under user control -->
			<a :href="href" :class="linkClass(isActive, isExactActive)" @click="navigate">
				<slot></slot>
			</a>
		</li>
	</router-link>
</template>

<script>
export default {
	name: "DemoMenuItem",
	props: {
		to: {
			type: String,
			default: "/",
		},
	},
	methods: {
		linkClass(isActive, isExactActive) {
			return [isActive && "is-active", isExactActive && "is-active"];
		},
	},
};
</script>
