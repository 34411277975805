<template>
	<div id="logo" class="logo">
		<div>
			<a href="/"><img src="../assets/logo.png" /></a>
			<a href="/"><h2 class="title is-2">Initech</h2></a>
		</div>
		<p>For all your stapling needs!</p>
	</div>
</template>

<script>
export default {
	name: "DemoLogo",
};
</script>

<style scoped>
.logo {
	margin-bottom: 2em;
}

@media (min-width: 769px) {
	.logo {
		border-bottom: 1px solid #efefef;
		margin-bottom: 0;
	}
}

img {
	width: 80px;
	display: inline-block;
	margin-right: 1em;
}
h2 {
	margin-bottom: 0.2em;
	display: inline-block;
	line-height: 1;
}
</style>
