<template>
	<div class="home">
		<h1 class="title">Product: {{ $route.params.id }}</h1>
		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
	</div>
</template>

<script>
export default {
	name: "DemoProduct",
};
</script>
