<template>
	<div id="searchbox" class="search">
		<form>
			<div class="field">
				<p class="control has-icons-left">
					<input
						id="search"
						v-model="term"
						class="input is-primary"
						type="text"
						placeholder="Search"
						@blur="onBlur"
						@keyup="onSearch"
					/>
					<span class="icon is-small is-left" tabindex="0" role="button">
						<i class="fas fa-search"></i>
					</span>
				</p>
			</div>
		</form>
		<nav id="results" class="results panel">
			<router-link
				v-for="result in results"
				:key="result"
				class="panel-block is-active"
				:to="`/product/${result}`"
			>
				{{ result }}
			</router-link>
		</nav>
	</div>
</template>

<script>
/* eslint-disable-next-line import/extensions, import/no-unresolved -- webpack import not understood by eslint import resolver (yet) */
import rawWords from "!raw-loader!@/words.txt";

const words = rawWords.split("\n").filter(Boolean);

export default {
	name: "DemoSearch",
	data() {
		return {
			term: "",
			results: [],
		};
	},
	methods: {
		async onBlur() {
			setTimeout(() => {
				this.term = "";
				this.results = [];
			}, 100);
		},
		onSearch() {
			if (this.term !== "") {
				this.results = words.filter((it) => it.includes(this.term)).slice(0, 10);
			} else {
				this.results = [];
			}
		},
	},
};
</script>

<style scoped>
.search {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	padding-bottom: 3em;
	border-bottom: 1px solid #efefef;
	position: relative;
}

form,
input {
	width: 100%;
}

@media (min-width: 769px) {
	input {
		width: auto;
	}
}

.results {
	position: absolute;
	top: 45px;
	left: 0;
	right: 0;
	background: #fff;
}
</style>
