<template>
	<div id="footer" class="footer">
		<div class="columns">
			<div class="column">
				<h5 class="title is-5">Contact</h5>
				<div class="menu">
					<ul class="menu-list">
						<li><a href="#">Email</a></li>
						<li><a href="#">Phone</a></li>
						<li><a href="#">FAQ</a></li>
					</ul>
				</div>
			</div>
			<div class="column">
				<h5 class="title is-5">Address</h5>
				<address>
					Trädgatan 29<br />
					863 33 Sundsbruk<br />
					Sweden
				</address>
			</div>
			<div class="column">
				<h5 class="title is-5">Work</h5>
				<div class="menu">
					<ul class="menu-list">
						<li><a href="#">Get hired</a></li>
					</ul>
				</div>
			</div>
			<div class="column">
				<h5 class="title is-5">Contact</h5>
				<div class="menu">
					<ul class="menu-list">
						<li><a href="#">Privacy policy</a></li>
						<li><a href="#">Cookies</a></li>
						<li><a href="#">Press &amp; media</a></li>
						<li><a href="#">API</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DemoFooter",
};
</script>

<style scoped>
.footer {
	border-top: 1px solid #ccc;
}
.menu-list {
	margin-left: -0.75em;
}
</style>
