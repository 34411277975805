<template>
	<aside class="menu">
		<p class="menu-label">General</p>
		<ul class="menu-list">
			<demo-menu-item to="/">Home</demo-menu-item>
			<demo-menu-item to="/about">About us</demo-menu-item>
		</ul>
		<p class="menu-label">Services</p>
		<ul class="menu-list">
			<demo-menu-item to="/store">Store</demo-menu-item>
			<demo-menu-item to="/rental">Rental</demo-menu-item>
			<demo-menu-item to="/hire">Hire</demo-menu-item>
		</ul>
		<p class="menu-label">Other</p>
		<ul class="menu-list">
			<demo-menu-item to="/discount">Discounts</demo-menu-item>
			<demo-menu-item to="/contact">Contact us</demo-menu-item>
		</ul>
	</aside>
</template>

<script>
import DemoMenuItem from "./DemoMenuItem.vue";

export default {
	name: "DemoMenu",
	components: { DemoMenuItem },
};
</script>

<style scoped>
.menu {
	background: #eee;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #efefef;
	padding: 1em;
	padding-top: 2em;
}
</style>
