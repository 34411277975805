<template>
	<div class="wrapper">
		<demo-logo />
		<demo-menu />
		<demo-main msg="Welcome to Your Vue.js App" />
		<demo-footer />
		<demo-search />
	</div>
</template>

<script>
import DemoLogo from "./components/DemoLogo.vue";
import DemoMain from "./components/DemoMain.vue";
import DemoMenu from "./components/DemoMenu.vue";
import DemoFooter from "./components/DemoFooter.vue";
import DemoSearch from "./components/DemoSearch.vue";

export default {
	name: "App",
	components: {
		DemoLogo,
		DemoFooter,
		DemoMain,
		DemoMenu,
		DemoSearch,
	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

*:focus {
	outline: 3px dashed #ff00aa;
	outline-offset: 3px;
}
</style>

<style scoped>
.wrapper {
	display: grid;
	grid-template-columns: 150px 1fr;
	grid-template-areas:
		"logo logo"
		"search search"
		"menu main"
		"footer footer";
}

@media (min-width: 769px) {
	.wrapper {
		grid-template-columns: 250px 1fr 250px;
		grid-template-areas:
			"logo logo search"
			"menu main main"
			"footer footer footer";
	}
}

.logo {
	grid-area: logo;
}

.menu {
	grid-area: menu;
}

.search {
	grid-area: search;
}

.footer {
	grid-area: footer;
}

.main {
	grid-area: main;
}
</style>
